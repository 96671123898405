import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect } from "react";
import { connect } from "react-redux";
// -----------------------------------------------------

import { logoutUser, setLocalUser } from "../components/user/actions";
import AppRouters from "./components/app_routers/AppRouters";
import IndexFooter from "./components/footer_component/indexFooter";
import FooterNavBar from "./components/header_component/footer_nav/FooterNavBar";
import IndexHeader from "./components/header_component/IndexHeader";

// import fire from "./config/fire";
// import LoginComponent from "./user_base/LoginComponent";
import "aos/dist/aos.css";
import "react-circular-progressbar/dist/styles.css";
// import "react-image-gallery/styles/css/image-gallery.scss";
import "react-multi-carousel/lib/styles.css";

import "../assets/scss/academy.scss";
import "../assets/scss/appointment.scss";
import "../assets/scss/club.scss";
import "../assets/scss/community.scss";
import "../assets/scss/contributor.scss";
import "../assets/scss/contributor_header.scss";
import "../assets/scss/contributor_overview.scss";
import "../assets/scss/contributor_sidenav.scss";
import "../assets/scss/dayscode.scss";
import "../assets/scss/email_template.scss";
import "../assets/scss/event.scss";
import "../assets/scss/footer.scss";
import "../assets/scss/freeclass.scss";
import "../assets/scss/header.scss";
import "../assets/scss/home.scss";
import "../assets/scss/landing_page.scss";
import "../assets/scss/landing_timeline.scss";
import "../assets/scss/login.scss";
import "../assets/scss/modal.scss";
import "../assets/scss/profile.scss";
import "../assets/scss/sagethon.scss";
import "../assets/scss/side_nav.scss";
import "../assets/scss/style.scss";
import "../assets/scss/tutorial.scss";
import "../assets/scss/wittyhacks.scss";
import "../assets/scss/blog.scss";
import "../assets/scss/interview.scss";
import "../assets/scss/aisummit.scss";

import { scheduleTokenExpiryLogout } from "../utils";
import FooterComponent from "./components/footer_component/FooterComponent";

const App = ({
  isShowFooter,
  setLocalUser,
  getUserProfile,
  hideFooter,
  hideHeader,
  logoutUser,
}) => {
  // const hideFooterPaths = ["#/days_code_problems"];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log(window.location.hash);
    const token = localStorage.getItem("access_token");
    if (token) {
      scheduleTokenExpiryLogout(token, logoutUser);
    }
  });

  return (
    <>
      <div className="wrapper">
        {!hideHeader && <IndexHeader />}

        <div className="" style={{ minHeight: "70vh" }}>
          <AppRouters />
        </div>

        {isShowFooter ? (
          <IndexFooter />
        ) : (
          <>
            {!hideFooter && (
              <>
                <IndexFooter hideTopFooter={false} />
                <FooterNavBar />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  setLocalUser,
  logoutUser,
};

const mapStateToProps = ({ app, user }) => ({
  isShowFooter: app.isShowFooter,
  hideFooter: app.hideFooter,
  hideHeader: app.hideHeader,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
