import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faLinkedinIn,
  faYoutube,
  faInstagram,
  faWhatsapp,
  faTwitter,
  faTelegramPlane,
} from "@fortawesome/free-brands-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const FooterComponent = ({ hideTopFooter }) => {
  return (
    <>
      <div className="mt-5 pt-3 pt-md-0 mb-0 footer-section">
        {!hideTopFooter && (
          <div className="row m-0 pt-5 px-md-5 px-3">
            <div className="col-12 col-md-3 mt-md-0 mt-3">
              <h6>Learning Resources</h6>
              <p>
                <Link to="/tutorials">
                  <span>Tutorial & Courses</span>
                </Link>
              </p>
              <p>
                <Link to="/index_training_certification">
                  <span>Training & Certificate</span>
                </Link>
              </p>
              <p>
                <Link to="/index_interview_questioins">
                  <span>Interview Preparation</span>
                </Link>
              </p>
              <p>
                <Link to="/index_quize_tests">
                  <span>Quiz Tests</span>
                </Link>
              </p>
            </div>
            <div className="col-12 col-md-3 mt-md-0 mt-3">
              <h6>Useful Links</h6>
              <p>
                <Link to="/add_tutorial">
                  <span>Add Tutorial</span>
                </Link>
              </p>
              <p>
                <Link to="/speaker_form">
                  <span>Become Datacode Speaker</span>
                </Link>
              </p>
              <p>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSf2J50Yt31YF5p-a6ZGyRLE6E-PCokOnbh8xh_PzGacwOM2Xw/viewform"
                  target="blank"
                >
                  <span>Join Volunteer Program</span>
                </a>
              </p>
            </div>
            <div className="col-12 col-md-3 mt-md-0 mt-3">
              <h6>Our Partners</h6>
              <p className="sub-footer-heading">Community Partner</p>
              <Link to="https://www.facebook.com/groups/DevCIndore">
                <span>Facebook Developer Circle, Indore</span>
              </Link>
            </div>
            <div className="col-12 col-md-3 logo-section mt-md-0 mt-3">
              <h6 className="text-uppercase font-weight-bold">Datacode.in</h6>
              <hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" />
              <p>
                We present to you an Online Learning Community. Where you can
                explore your learning path and also get the latest and Best
                online learning Content over the internet.
              </p>
            </div>
          </div>
        )}
        <div className="row m-0 px-md-5 px-3 d-flex align-items-center">
          <div className="col-md-8 col-12">
            <div className="row m-0 align-items-center">
              <div className="col-12 col-md-2 p-md-0 align-items-center text-left">
                <h6>
                  <span>
                    Follow us on <i className="fal fa-long-arrow-right" />
                  </span>
                </h6>
              </div>
              <div className="col-12 px-0 col-md-10 text-left">
                <a href="https://www.facebook.com/Datacode.in/" target="_blank">
                  <FontAwesomeIcon
                    icon={faFacebookSquare}
                    size="2x"
                    color="#b89ddb"
                    className="m-3"
                  />
                </a>
                <a
                  href="https://www.instagram.com/datacode.in/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    color="#b89ddb"
                    className="m-3"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/datacode_in/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    size="2x"
                    color="#b89ddb"
                    className="m-3"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCEnd8Limn06xmD6hLzaGxvA"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    size="2x"
                    color="#b89ddb"
                    className="m-3"
                  />
                </a>
                <a href="https://twitter.com/Datacode_in" target="_blank">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="2x"
                    color="#b89ddb"
                    className="m-3"
                  />
                </a>
                <a
                  href="https://t.me/joinchat/Fc4UnJ4PxbUT-O5m"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faTelegramPlane}
                    size="2x"
                    color="#b89ddb"
                    className="m-3"
                  />
                </a>
                <a
                  href="https://chat.whatsapp.com/DrJNupSO3Go5kiNaO17sgq"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    size="2x"
                    color="#b89ddb"
                    className="m-3"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 pb-3 pb-md-0 col-12 d-flex justify-content-between">
            <Link to="/our_story">
              <span>Our Story</span>
            </Link>
            <Link to="/our_partners">
              <span>Our Partners</span>
            </Link>
            <Link to="/contact_us">
              <span>Become a partner</span>
            </Link>
          </div>
        </div>
        <div
          className="row m-0 text-center d-flex justify-content-center py-2"
          style={{ backgroundColor: "#30006d" }}
        >
          <h6
            className="text-center shadow-lg bg-white rounded-lg p-2"
            href="https://datacode.in/"
            style={{ color: "#000" }}
          >
            Made with <FontAwesomeIcon icon={faHeart} size="1x" color="red" />{" "}
            by Datacode Team
          </h6>
        </div>
      </div>
    </>
  );
};

export default FooterComponent;
